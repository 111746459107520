import { allGameIds } from "./games";
import { GameId } from "../../../blast-api/src/shared/schemas/GameId";

const globalPages = ["/", "/profile", "/privacy-policy"];

/**
 * Get the game id from the current location
 * eg. /dota/news -> dota
 *
 * This helper function does not account for the enabled games, just returns the game id from the list of all game ids
 *
 * @returns GameId | null
 */
export const getGameIdFromPathname = ({ pathname }: { pathname: string }): GameId | null => {
  if (globalPages.includes(pathname)) {
    return null; // null means no game - global context
  }
  const gameSegment = pathname.toLowerCase().split("/")[1];
  if (allGameIds.includes(gameSegment as GameId)) {
    return gameSegment as GameId;
  }

  // /supercell routes have been moved under the gaming context
  if (gameSegment === "supercell") {
    return "gaming";
  }

  // default to cs for all paths that aren't root or other games
  // we need to slowly migrate CS content to /cs
  return "cs";
};
