import { ReactNode, createContext, useContext } from "react";
import { useLocation } from "@remix-run/react";
import { GameId } from "../../../blast-api/src/shared/schemas/GameId";
import { getGameIdFromPathname } from "./getGameIdFromPathname";

const enabledGames: GameId[] = ["cs", "dota", "rl", "gaming"];

type GameIdContextValue = {
  gameId: GameId | null; // current game id, null means global context
  enabledGames: GameId[]; // game ids that are enabled via feature flags
};

export const GameIdContext = createContext<GameIdContextValue>({
  gameId: null,
  enabledGames,
});

export const GameIdProvider = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const gameIdFromLocation = getGameIdFromPathname({ pathname });

  const gameId =
    gameIdFromLocation === null ? null : enabledGames.includes(gameIdFromLocation) ? gameIdFromLocation : "cs";

  return <GameIdContext.Provider value={{ gameId, enabledGames }}>{children}</GameIdContext.Provider>;
};

export const StaticGameIdProvider = ({ children, gameId }: { children: ReactNode; gameId: GameId }) => {
  return <GameIdContext.Provider value={{ gameId, enabledGames }}>{children}</GameIdContext.Provider>;
};
